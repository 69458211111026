import { useStore } from 'vuex'

export const useDrawer = () => {
  const store = useStore()
  const isDrawerShown = computed(() => store.getters.isDrawerShown)
  const drawerOptions = computed(() => store.getters.drawerOptions)

  function updateDrawerState(params: any) {
    store.dispatch('updateDrawerState', params)
  }

  function closeDrawer() {
    if (isDrawerShown.value) {
      store.dispatch('updateDrawerState', {
        isDrawerShown: false,
      })
    }
  }

  return {
    isDrawerShown,
    drawerOptions,
    updateDrawerState,
    closeDrawer,
  }
}
